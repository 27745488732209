<template>
  <b-container style="padding: ">
    <b-row style="display: flex; flex-direction: column; align-items: center; justify-content: center">
      <b-col cols="12" md="6">
        <b-card>
          <div class="d-flex flex-column align-item-center justify-content-center">
            <img :src="daikinLogo" class="warranty-logo"/>

            <h3>Thank you for your submission. You will receive an email confirmation from us soon. Kindly call us at +65 6583 8888 if you have any queries.</h3>

            <a href="https://www.daikin.com.sg/" style="margin: 10px auto 0 auto;" target="_blank">
              &gt;&gt; Click here to go back to daikin homepage &lt;&lt;
            </a>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  data() {
    return {
      daikinLogo: require('@/assets/images/drcc/daikin-logo.png'),
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'center')
  }
}

</script>

<style>
.warranty-logo {
  width: 100%;
  height: auto;
  margin: 0 auto 15px auto;
}
</style>
